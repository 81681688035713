import { X } from "@phosphor-icons/react";
import styles from "./Piece.module.css";

export default function Piece({piece, removePiece}) {
  const pieceCost = piece.chain.price + piece.charms.reduce((priceAcc, curr) => priceAcc + curr.price, 0);

  return (
    <div className={styles.BagPiece}>
      <button onClick={() => removePiece()}>
        <X style={{color: "darkgray"}}/>
      </button>
      <div className={styles.pieceInfo}>
        <div className={styles.pieceRow}>
          <span>{piece.chain.alt}</span>
          <span>${piece.chain.price}</span>
        </div>
        {
          piece.charms.map(charm => 
            <div className={styles.pieceRow}>
              <span className={styles.charm}>
                <img src={charm.iconUrl} alt={charm.alt} style={{width: "14px"}}/>
                <span>{charm.alt}</span>
              </span>
              <span>${charm.price}</span>
            </div>
          )
        }
        <div className={styles.pieceRow}>
          <span></span>
          <span className={styles.pieceTotalCost}>${pieceCost}</span></div>
      </div>
    </div>
  )
}