import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import styles from "./Checkout.module.css"

export default function PaymentForm({ cart }) {
  const stripe = useStripe();
  const elements = useElements();

  async function submitOrder(event) {
    event.preventDefault();

    // validate form data
    const {error: submitError} = await elements.submit();
    if (submitError) {
      return;
    }

    // create ConfirmationToken
    const {error, confirmationToken} = await stripe.createConfirmationToken({
      elements
    });

    const res = await fetch("/api/create-confirm-intent", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        confirmationTokenId: confirmationToken.id,
        items: cart
      })
    });

    const data = await res.json();
  }

  return (
    <form onSubmit={submitOrder}>
      <PaymentElement/>
      <button className={styles.payButton}>Pay now</button>
    </form>
  );
}
