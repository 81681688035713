import { ShoppingBag, Check, CaretLeft, CaretRight } from "@phosphor-icons/react";
import styles from "./Order.module.css";
import necklace from "../../assets/necklace.png";

export default function OptionsBar({addToCart, selectPrevChain, selectNextChain, openCheckout}) {
  return (
    <div className={styles.optionsBar}>
      <button onClick={addToCart}>
        <Check/>
      </button>
      <div>
        <button onClick={selectPrevChain}>
          <CaretLeft size={24}/>
        </button>
        <img alt="chain icon" src={necklace} style={{width: "28px"}}/>
        <button onClick={selectNextChain}>
          <CaretRight size={24}/>
        </button>
      </div>
      <button onClick={openCheckout}>
        <ShoppingBag size={32}/>
      </button>
    </div>
  );
}