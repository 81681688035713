import {DndContext} from "@dnd-kit/core";
import DraggableCharm from "./DraggableCharm";
import DrawerDropAreas from "./DrawerDropAreas";

export default function DesignArea({activeCharms, moveCharm, deleteCharm, setDrawerExpanded, activeChainUrl}) {
  return (
    <DndContext onDragEnd={handleDragEnd} onDragMove={handleDragMove}>
      <DrawerDropAreas />
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${activeChainUrl})`,
          backgroundSize: "auto 80%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}
      >
        {
          activeCharms.map(c => <DraggableCharm charm={c} selected={true}/>)
        }
      </div>
    </DndContext>
  );

  function handleDragMove({over}) {
    if (!over) return;

    if (over.id === "hide-drawer-area") {
      setDrawerExpanded(true);
    } else if (over.id === "expand-drawer-area") {
      setDrawerExpanded(false)
    }
  }

  function handleDragEnd({active, over, delta}) {
    if (!over) return;

    if (over.id === "hide-drawer-area") {
      deleteCharm(active.id);
    } else {
      moveCharm(active.id, delta);
    }
  }
}